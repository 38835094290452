import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { cameraValidation } from '../../utils/validation';
import { validationDB } from '../../utils/checkDataBase';
import { isLocalPrinterReady, printDatamatrixSerialNumber, printDatamatrixFull, printBarCode, printDatamatrixSim, printBarCodeBatch } from '../../utils/prodlocalhelper';
import { isValidSim, isValidSerialNumber } from '../../utils/validation';
import { formatActivationCode } from '../../utils/formatHelper';
import { getSite, getProductPageLink } from "../../utils/site";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import cameraApi from "../../api/cameraApi";
import masterpackApi from "../../api/masterpackApi";
import cameraModelApi from "../../api/cameraModelApi";
import { searchQuery } from "../../actions/searchActions";
import SdCard from "./sdCard" 
import ButtonFactory from "../../components/material/ButtonFactory";
import acceptSims from "../../utils/acceptSims";
import ModalFactory from "../../components/modal/ModalFactory"
import ReactTableBuilder from "../../utils/reactTableBuilder";
import simSupplierApi from "../../api/simSupplierApi";

const handleFocus = (event) => event.target.select();

class ViewCamera extends Component {
  constructor() {
    super();
    this.state = {
      activationCode: "",
      cam: [],
      camBarcode: "",
      camBefore: "",
      camera: {
        id: "",
        sims: [],
        serialNumber: "",
        cameraModel: "-- Select a camera model --",
        imei: "",
        masterPackId: ""
      },
      cameraModels: [],
      simSuppliers: [],
      camId: "",
      disabledNext: false,
      disabledPrevious: true,
      editable: false,
      errors: {},
      index: 0,
      input: "",
      MasterBarcode: "",
      printerName: "",
      printerStatus: "Loading...",
      QTY: "",
      quantity: 1,
      reprintError: "",
      scanner: "",
      search: "",
      searchResp: [],
      searchResult: [],
      stages: [],
      sticker: "",
      update: false,
      snackBar: [],
      snackBarError: [],
      showSdCard: false,
      acceptedSims: [],
      showSimError: false,
      simNumber: ""
    };

    if (localStorage.cameraModels) {
      try {
        this.state.cameraModels = JSON.parse(localStorage.cameraModels);
      } catch (e) {
      }
    }
    // this.onSubmit = this.onSubmit.bind(this);
    // this.handleKeyPress = this.handleKeyPress.bind(this);
  };

  UNSAFE_componentWillMount() {
    if(this.props.match.params.id) {
      cameraApi.getCamera(this.props.match.params.id)
      .then(res => {
        this.setState({ cam: res.data.footprints })
      })
      .catch(err => {
        const errors = { general: "Camera not found" };
        this.setState({ errors: errors })
      });
    }
  }

  getSimLetter = sim => {
    const matchingSimSupplier = this.state.simSuppliers.find(simSupplier => simSupplier.isActive && simSupplier.prefix === sim)
    return matchingSimSupplier && matchingSimSupplier.letter
  }

  loadSimSuppliers = () => {
    simSupplierApi.getSimSuppliers()
      .then(res => {
        this.setState({ simSuppliers: res.data })
      })
      .catch(err =>
        this.setState({ simSuppliers: [] })
      );
  }

  componentDidMount() {
    const { t } = this.props;
    this.loadSimSuppliers()
    this.loadCamerasModels();

    if(this.props.match.params.id) {
      cameraApi.getCamera(this.props.match.params.id)
      .then(res => {
        this.setState({ camera: res.data })
        this.setState({ camBefore: res.data  });
        let activationCode = formatActivationCode(res.data.activationCode)
        this.setState({ activationCode: activationCode })
        const masterPackID = this.state.camera.masterPackId
        if (masterPackID) {
          //trouver les infos sur le masterPack
          masterpackApi.getMasterpack(masterPackID)
            .then(res => {
              this.setState({ MasterBarcode: res.data.barcode, camBarcode: res.data.cameraModel.barcode })
            })
            .catch(err => {
              const errors = { general: "Camera not found" };
              this.setState({ errors: errors })
            });
        }
      })
      .catch(err => {
        const errors = { general: "Camera not found" };
        this.setState({ errors: errors })
      });
    }

    isLocalPrinterReady((err, res) => {
      if (err) {
        let errors = { printer: err }
        this.setState({
          printerStatus: "ERROR",
          printerName: t("dashboard_main.local_printer_not_ready"),
          errors: errors
        })
      } else {
        this.setState({
          printerStatus: res.data.status,
          printerName: res.data.name
        })
      }
    });

    this.loadProductStage();
    this.setState({ sticker: 'none' })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    // if (nextProps.newmasterpack.items) {
    //   this.setState({
    //     items: nextProps.newmasterpack.items
    //   });
    // }
    if (nextProps.search.searchResult.length !== 0 && this.state.scanner) {
      this.setState({ searchResult: nextProps.search.searchResult
      });
      const newId = nextProps.search.searchResult[0].search.id;
      this.loadScan(newId);
    } else {
      this.setState({ errors: { scanner: t('dashboard_main.scanNotRecognized') } });
    }
    // if (nextProps.errors) {
    //   this.setState({
    //     errors: nextProps.errors
    //   });
    // }
  }

  openSnackBar = (text, isSuccess) => {
    const newSnackBar = [];
    newSnackBar.push({text: text})
    if (isSuccess === true) {
      this.setState({snackBar: newSnackBar});
    } else {
      this.setState({snackBarError: newSnackBar});
    }
    setTimeout(() => {
      const emptyArray = [];
      this.setState({snackBar: emptyArray, snackBarError: emptyArray});
      }, 3000);
  }

  loadScan = id => {
    cameraApi.getCamera(id)
    .then(res => {
      this.setState({ camera: res.data })
      this.setState({ camBefore: res.data  });
      let activationCode = formatActivationCode(res.data.activationCode)
      this.setState({ activationCode: activationCode, cam: res.data.footprints })
      const masterPackNid = this.state.camera.masterPackId
      if (masterPackNid) {
        //trouver les infos sur le masterPack
        masterpackApi.getMasterpack(masterPackNid)
          .then(res => {
            this.setState({ MasterBarcode: res.data.barcode, camBarcode: res.data.cameraModel.barcode })
          })
          .catch(err => {
            const errors = { general: "Camera not found" };
            this.setState({ errors: errors })
          });
      }
      cameraModelApi.getCameraModel(res.data.cameraModel).then(resCamModel => {
        this.setState({ acceptedSims: resCamModel.data.optionsSims })
      })
    })
    .catch(err => {
      const errors = { general: "Camera not found" };
      this.setState({ errors: errors })
    });
  }

  loadCamerasModels = e => {
    const { t } = this.props;

    if (localStorage.cameraModels) {
      try {
        var models = JSON.parse(localStorage.cameraModels)
        models.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        this.setState({ cameraModels: [{ "name": t("global.selectAModel") }, ...models] })
      } catch (e) {
      }
    } else {
      cameraModelApi.getCameraModels()
        .then(res => {
          let models = res.data;
          localStorage.setItem("cameraModels", JSON.stringify(models));
          models = [{ "name": t("global.selectAModel") }, ...models]
          console.log(models)
          this.setState({ cameraModels: models })
        })
        .catch(err =>
          localStorage.setItem("cameraModels", JSON.stringify([]))
        );
    }
  }

  handleKeyPress = e => {
    const { t } = this.props;
    this.setState({ errors: {} });

    let valid = false;
    let editValue;

    if (e.key === 'Enter' && this.state.scanner !== "" && (isValidSim(e.target.value) || isValidSerialNumber(e.target.value) || (e.target.value.length <= 13))) {
      editValue = this.state.scanner.replace(/-/g, ""); //Edit for Activation code
      valid = true;
    
      const searchData = {
        search: editValue,
        date: Date()
      };

      this.onChange(e);

      //Send to api only if valid is true
      if(valid) {
        this.props.searchQuery(searchData);
        e.preventDefault();
      }
    } else if (e.key === 'Enter' && this.state.scanner !== "" && !isValidSim(e.target.value) && !isValidSerialNumber(e.target.value) && e.target.value.length > 13) {
      let imei, sn = ""
      if (e.target.value.split(/,|;/).length === 4) {
        imei = e.target.value.split(/,|;/)[1]
        sn = e.target.value.split(/,|;/)[2]
      } else {        
        sn = e.target.value.split(/,|;/)[0]
      }
      // this.setState({ serialNumber: sn })
      // const datamatrix = `${sn},${getProductPageLink()},`.padEnd(45,'-')
      if (imei) {
        this.loadScan(imei);
      } else {
        this.loadScan(sn);
      }      
      e.preventDefault();
    } else if (e.key === 'Enter' && (this.state.scanner === "" || !isValidSim(e.target.value) || !isValidSerialNumber(e.target.value))) {
      this.setState({ errors: { scanner: t('dashboard_main.scanNotRecognized') } });
      e.preventDefault();
    }
  }

  onChange = e => {
    const { t } = this.props;
    this.setState({ [e.target.id]: e.target.value });

    if (e.target.id !== "deleteCam" && e.target.id !== "printCam" && e.target.id !== "sticker" && e.target.id !== "scanner"  ) {
      this.setState({ errors: {} })
      let editText = true
      if (e.target.type === "button") {
        if (this.state.editable === false) {
          editText = true
        } else {
          editText = false
        }
      }

      let val = e.target.value
      if (e.target.id.indexOf("camera.") !== -1) {
        var camera = { ...this.state.camera }
        let newId = e.target.id.replace("camera.", "");

        if (!Array.isArray(val) && (newId === "sims[0]" || newId === "sims[1]" )) {

          let newSim = val.split(/,|;/)
          //newSim = Array.from(newSim)
          const arrIndex = newId.slice(4,7)

          if (arrIndex === "[0]" && camera.sims.length > 1 && val !== "") { //Change 1 sim [0]
            newSim = [val, camera.sims[1]]
          } else if (arrIndex === "[1]" && camera.sims.length > 1 && val !== "") { //Change 1 sim [1]
            newSim = [camera.sims[0], val]
          } else if (arrIndex === "[0]" && camera.sims.length > 1 && val === "") { //Remove 1 sim [0]
            newSim = [camera.sims[1]]
          } else if (arrIndex === "[1]" && camera.sims.length > 1 && val === "") { //Remove 1 sim [1]
            newSim = [camera.sims[0]]
          } else if (arrIndex === "[1]" && camera.sims.length === 1) { //Add 1 sim [1]
            newSim = [camera.sims[0], val]
          } else {
            newSim = [val] //Add 1 sim [0]
          }

          newId = newId.replace(arrIndex, "");
          if (newSim[0] === "") { newSim = []; };
          this.setState({ "camera": { ...camera, [newId]: newSim } });
        } else {
          this.setState({ "camera": { ...camera, [newId]: val } });
        }
      } else {
        this.setState({ [e.target.id]: val });
      }

      if (e.target.type === "select-one") {
        cameraModelApi.getCameraModel(e.target.value)
          .then(res => {
            let models = res.data;
            this.setState({ camBarcode: models.barcode, acceptedSims: res.data.optionsSims })
          })
          .catch(err =>
            console.log("error : " + err)
          );
      }
      this.setState({ editable: editText })
    }

    //Delete Cam
    if(e.target.id === "deleteCam" && !this.state.camera.deleted) {
      let valId = e.target.value

      // eslint-disable-next-line no-restricted-globals
      let confirmMessageDel = confirm(t('message.confirmDeleteCam'));
      if (confirmMessageDel) {
        const newStage = {
          step: "deleted",
          notes: "from search screen"
        }

        cameraApi.putCameraFootprint(valId, newStage)
        .then(res => {
          this.setState({ camera: {sims: "", serialNumber: "",cameraModel: "-- Select a camera model --", masterPackId: "", imei: ""}, MasterBarcode: "", cam: [], camBarcode: "", activationCode: "", scanner: "" })
        })
        .catch(err => {
          const errors = err.response.data
          if (err.response.status === 404) {
            errors.valid = false;
            errors.deleted = t(err.response.message)
          }
          this.setState({ errors: errors })
        });
      }
    } else if (e.target.id === "deleteCam") {
      const errors = { deleted: t('inventory.viewCamera.deleteError')};
      this.setState({ errors: errors })
    }
  };

  onSubmit = async (e, flag) => {
    const { t } = this.props;
    const { camera } = this.state;
    const camBefore  = this.state.camBefore;
    const validation = cameraValidation(camera);

    var isSimCanBeAdded = true
    if (flag != "byPassSim") {
      camera.sims.forEach(sim => {
        if (isSimCanBeAdded == true) {
          isSimCanBeAdded = acceptSims(sim, this.state.acceptedSims, this.props.auth.user.role, this.showModalYesNo)
        }
      });
  
      if (isSimCanBeAdded == false) {
        validation.valid = false;
        validation.sim = "That Sim is not accepted to match that CameraModel";
      }
  
      if (isSimCanBeAdded === undefined) {
        validation.valid = false;
      }
    }


    this.setState({ errors: validation });
    
    //this.onChange(e);
    
    let camModel, camSN, camSim, camImei;
    let modified = [];
    let objForCheckDB = {};

    const checkModel = !Object.is(camBefore.cameraModel, camera.cameraModel)
    var checkSim0 = !Object.is(camBefore.sims[0], camera.sims[0])
    const checkSim1 = !Object.is(camBefore.sims[1], camera.sims[1])
    const checkSN = !Object.is(camBefore.serialNumber, camera.serialNumber)
    const checkImei = !Object.is(camBefore.imei, camera.imei)

    if (validation.valid) {
      // Message for footprint
      if (checkModel) {
        camModel = "camera model";
        modified.push(camModel);
      }
      if (checkSN) {
        camSN = "serial number";
        modified.push(camSN);
        objForCheckDB.serialNumber = camera.serialNumber;
      }
      if (checkSim0 || checkSim1) {
        camSim = "sim card number";
        modified.push(camSim);
        if (checkSim0 && camera.sims[0]) {
          objForCheckDB.sims0 = camera.sims[0];
        }
        if (checkSim1 && camera.sims[1]) {
          objForCheckDB.sims1 = camera.sims[1];
        }
      }
      if (checkImei) {
        camImei = "IMEI";
        modified.push(camImei);
        if (camera.imei) {
          objForCheckDB.imei = camera.imei;
        }
      }
    }

    if (validation.valid) {
      if(checkModel && !checkSim0 && !checkSim1 && !checkSN && !checkImei) {
        const newData = { cameraModel: camera.cameraModel }
        // eslint-disable-next-line no-useless-concat
        let footText = modified.join() + " modified";
        const newStage = { step: "edition", notes: footText }
        this.onSaveSubmit(camera.id, newStage, newData)
      } else if (Object.values(objForCheckDB).length !== 0) {
        // eslint-disable-next-line no-unused-vars
        const valDB = validationDB(this.state.camera.id, objForCheckDB, async (err, res) => {
          this.setState({ errors: res});
          if (res.valid) {
            const newData = {
              sims: camera.sims,
              serialNumber: camera.serialNumber,
              cameraModel: camera.cameraModel,
              imei: camera.imei
            }
            let footText = "";
            if (modified.length === 1) {
              // eslint-disable-next-line no-useless-concat
              footText = modified.join() + " modified";
            } else {
              // eslint-disable-next-line no-useless-concat
              footText = modified.join(', ') + " modified";
            }
            const newStage = { step: "edition", notes: footText }
            this.onSaveSubmit(camera.id, newStage, newData)
          }
        });
      } else {
        const newData = {
          sims: camera.sims,
          serialNumber: camera.serialNumber,
          cameraModel: camera.cameraModel,
          imei: camera.imei
        }
        let footText = "";
        if (modified.length === 1) {
          // eslint-disable-next-line no-useless-concat
          footText = modified.join() + " modified";
        } else {
          // eslint-disable-next-line no-useless-concat
          footText = modified.join(', ') + " modified";
        }
        const newStage = { step: "edition", notes: footText }
        this.onSaveSubmit(camera.id, newStage, newData)
      }
    }
    this.setState({ editable: false })
    e.preventDefault(); //no submit
  };

  showModalYesNo = e => {
    this.setState({ showSimError: true })
  }

  cancelYesNo = e => {
    var {t} = this.props
    this.setState({  showSimError: false, errors: { valid: false, sim : t('error_sims_not_accepted')}})
    return false
  }

  acceptYesNo = e => {
    const { t } = this.props;
    this.setState({ showSimError: false })
    this.onSubmit(e, "byPassSim")
    return true
  }


  onSaveSubmit = (id, stage, data) => {
    const { t } = this.props;
      cameraApi.putCameraFootprint(id, stage)
      .then(res => {
        // eslint-disable-next-line no-unused-vars
        let test = res.config.data;
        cameraApi.putCamera(id, data)
        .then(res => {
          this.openSnackBar(t('cameraSaveSuccess'), true)
          this.setState({ editable: false })
          this.loadScan(id);
        })
        .catch(err => {
          const errors = err
          if (errors.status === "409") {
            errors.valid = false;
            errors.sims = errors.error
          }
          this.openSnackBar(t('cameraSaveError'), false)
          this.setState({ errors: errors })
        });
      })
      .catch(err => {
        const errors = err.response.data
        if (err.response.status === 404) {
        errors.valid = false;
        errors.deleted = t(err.response.message)
        }
        this.setState({ errors: errors })
        this.openSnackBar(t('cameraSaveError'), false)
      });
  }

  onPrevious = () => {
    let j = this.state.index;

    if (j !== 0 && j <= (this.state.searchResult.length - 1)) {
      j -= 1;
      this.loadScan(this.state.searchResult[j].search.id);
      this.setState({ index: j, disabledNext: false });
      if (j === 0) {
        this.setState({ disabledPrevious: true });
      }
    }
  }

  onNext = () => {
    let i = this.state.index;

    if (i < (this.state.searchResult.length - 1)) {
      i += 1;
      this.loadScan(this.state.searchResult[i].search.id);
      this.setState({ index: i, disabledPrevious: false });
      if (i === (this.state.searchResult.length - 1)) {
        this.setState({ disabledNext: true });
      }
    }
  }

  changeView = () => {
    this.setState({showSdCard: !this.state.showSdCard})
  }

  loadProductStage = () => {
    const { t } = this.props
    this.setState({
      stages: [{
        'id': 'none',
        'name': t('global.selectPrint')
      },
      {
        'id': 'Full',
        'name': t('input.reprint_full_dt')
      },
      {
        'id': 'serial_dt',
        'name': t('input.reprint_serial_number')
      }
      ]
    })
  };

  onPrintCam = e => {
    const { t } = this.props
    let activationCode, imei, serialNumber, indice = "";
    let currentSims, indicator = [];
    let sim1, sim2;
    this.setState({ errors: {}, reprintError: "", errorPrint: "" });

    if (!this.state.camera.id) {
      indice = this.state.camId
    } else {
      indice = this.state.camera.id
    }

    this.setState({ indice: indice })
    if (this.state.sticker !== "sim_barcode_sim" && this.state.sticker !== "sim_datamatrix_sim" && this.state.sticker !== "sn_batch") {

      cameraApi.getCamera(indice)
      .then(async (res) => {
        this.setState({ camId: res.data.id })
        activationCode = res.data.activationCode
        imei = res.data.imei
        serialNumber = res.data.serialNumber
        currentSims = res.data.sims

        if (this.state.sticker === "none") {
          this.setState({ reprintError: "Select a print option" })
        } else if (this.state.sticker === "Full") {
          var model = await cameraModelApi.getCameraModel(res.data.cameraModel)
          const data = {
            datamatrix: `${activationCode},${imei},${serialNumber},${model.data.commonName}`.padEnd(45, '-'),
            activationCode: formatActivationCode(activationCode),
            serialNumber: serialNumber,
            numberOfCopies: 1
          }
          printDatamatrixFull(data, (err, res) => {
            if (err) {
              let errors = { printer: err.message }
              this.setState({
                printerStatus: "ERROR",
                errors: errors,
                errorPrint: "Error - Not able to print this option"
              })
            } else {
              this.openSnackBar(t('printSuccess'), true)
            }
          });
        } else if (this.state.sticker === "serial_dt") {
          if (currentSims.length === 1) {
            sim1 = currentSims[0].slice(0,5)
            indicator.push(this.getSimLetter(sim1));
          } else if (currentSims.length > 1) {
            sim1 = currentSims[0].slice(0,5)
            sim2 = currentSims[1].slice(0,5)
            indicator.push(this.getSimLetter(sim1));
            indicator.push(this.getSimLetter(sim2));
          }

          const data = {
            serialNumber: serialNumber,
            datamatrix: `${serialNumber},${getProductPageLink()},`.padEnd(45, '-'),
            simsIndicator: indicator,
            numberOfCopies: 1
          }
          printDatamatrixSerialNumber(data, (err, res) => {
            if (err) {
              let errors = { printer: err.message }
              this.setState({
                printerStatus: "ERROR",
                errors: errors,
                errorPrint: "Error - Not able to print this option"
              })
            } else {
              this.openSnackBar(t('printSuccess'), true)
            }
          });
        }
        else if (this.state.sticker === "sim_barcode") {
          for (let i = 0; i < currentSims.length; i++) {
            if (isValidSim(currentSims[i])) {
              let data = {
                barCode: [currentSims[i]],
                sim: currentSims[i],
                numberOfCopies: 1
              }
              printBarCode(data, (err, res) => {
                if (err) {
                  let errors = { printer: err.message }
                  this.setState({
                    printerStatus: "ERROR",
                    errors: errors,
                    errorPrint: "Error - Not able to print this option"
                  })
                } else {
                  this.openSnackBar(t('printSuccess'), true)
                }
              });
            }
          }
        }
        else if (this.state.sticker === "sn_barcode") {
          const data = {
            serialNumber: serialNumber,
            barCode: serialNumber,
            numberOfCopies: 1
          }
          printBarCode(data, (err, res) => {
            if (err) {
              let errors = { printer: err.message }
              this.setState({
                printerStatus: "ERROR",
                errors: errors,
                errorPrint: "Error - Not able to print this option"
              })
            } else  {
              this.openSnackBar(t('printSuccess'), true)
            }
          });
        }
      })
      .catch(err => {
        this.setState({ errors: { input: "Error - not able to find camera" } })
      });
    } /*** NEW SCOPE ***/
    else {
      let newCurrentSims = [];
      newCurrentSims = this.state.camera.sims;
      let currentSerialNumber = this.state.camera.serialNumber;

      if (this.state.sticker === "sim_datamatrix_sim") {
        for (let i = 0; i < newCurrentSims.length; i++) {
          if (isValidSim(newCurrentSims[i])) {
            let data = {
              datamatrix: `${newCurrentSims[i]},${getProductPageLink()}`,
              sim: newCurrentSims[i],
              numberOfCopies: 1
            }
            printDatamatrixSim(data, (err, res) => {
              if (err) {
                let errors = { printer: err.message }
                this.setState({
                  printerStatus: "ERROR",
                  errors: errors,
                  errorPrint: "Error - Not able to print this option"
                })
              } else {
                this.openSnackBar(t('printSuccess'), true)
              }
            });
          }
        }
      }
      else if (this.state.sticker === "sn_batch") {
        if (isValidSerialNumber(currentSerialNumber)) {
          const data = {
            serialNumber: currentSerialNumber,
            barCode: currentSerialNumber,
            numberOfCopies: this.state.quantity
          }
          printBarCodeBatch(data, (err, res) => {
            if (err) {
              let errors = { printer: err.message }
              this.setState({
                printerStatus: "ERROR",
                errors: errors,
                errorPrint: "Error - Not able to print this option"
              })
            } else {
              this.openSnackBar(t('printSuccess'), true)
            }
          });
        }        
      }
    }    
  }

  render() {
    const { cam, errors, cameraModels, sticker, stages } = this.state;
    const { user } = this.props.auth;
    const { t } = this.props;

    const columns = [
      {
        Header: t('inventory.viewCamera.footprintStep'),
        accessor: 'step',
      },
      {
        Header: t('inventory.viewCamera.footprintDate'),
        accessor: row => row.date ? new Date(row.date).toLocaleString(navigator.language, { hour12: false }).replace(/,/g, '') : ""
      },
      {
        Header: t('inventory.viewCamera.footprintUser'),
        accessor: 'instigator.name',
      },
      {
        Header: t('inventory.viewCamera.footprintNotes'),
        accessor: 'notes',
      },
    ]

    return (
      <>
      { this.state.showSdCard &&
        <SdCard  button={this.changeView} />
      }
      { !this.state.showSdCard &&
      <div>
        <div style={{ marginTop: "30px", minHeight: "75vh", minWidth: "90%" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              {/* Page title */}
              {
                    this.state.showSimError === true && 

                    <ModalFactory modalMode="modalYesNo" buttonSaveText={t('overwrite_sims')} 
                     buttonCancelText={t('cancel')} 
                    onClickSave={this.acceptYesNo} 
                    onClickCancel={this.cancelYesNo}
                    show={this.state.showSimError} 
                    title={t('title_overwrite_sims')} 
                    paragraph={t('paragraph_overwrite_sims')} />
                }
              <Row style={{ marginBottom: "1rem" }}>
                <Col s={12} m={3} >
                  <ButtonFactory style={{ marginTop: "2rem" }} name="SD Card View" text={t('sd_card_view')} icon='edit' onClick={this.changeView} />
                </Col>
                <Col s={12} m={6} >
                  <h4>{t('dashboard_main.productDetail')}</h4>
                </Col>
                <Col s={12} m={2}>
                  <i className={classnames("large material-icons", {
                    "green-text": this.state.printerStatus === "ready",
                    "red-text": this.state.printerStatus !== "ready"
                  })}>print</i>
                  <div>{this.state.printerName}</div>
                </Col>
              </Row>
              <form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <div className="input-field col s3">
                    <input
                      onChange={this.onChange}
                      onFocus={handleFocus}
                      onKeyPress={this.handleKeyPress}
                      value={this.state.scanner}
                      error={errors.scanner}
                      id="scanner"
                      type="text"
                      autoFocus
                      className={classnames("", {
                      invalid: errors.scanner
                      })}
                    />
                    <label htmlFor="scanner">{t('dashboard_main.scanner')}</label>
                    <span id="scannerMessage" className="red-text">
                        {errors.scanner}
                    </span>
                  </div>

                  <div className="input-field col s2">
                    <span id="foundMessage" style={ this.state.searchResult.length < 2 ? { visibility: 'hidden'} : {display: 'block'} }>{t('inventory.viewCamera.foundCameras', {myVar: this.state.searchResult.length})}
                    </span>
                  </div>

                  <div className="input-field col s2" >
                    <div className={user.role === "user" ? "hide" : null} style={ this.state.searchResult.length < 2 ? { visibility: 'hidden'} : {display: 'block'} }>
                      <button type="button"
                        onClick={this.onPrevious}
                        className="btn waves-effect waves-light"
                        id="previousModel"
                        disabled={this.state.disabledPrevious}
                        style={{ width: "100%" }}
                      >{t('inventory.viewCamera.previousModel')}
                      </button>
                    </div>
                  </div>

                  <div className="input-field col s2" >
                    <div className={user.role === "user" ? "hide" : null} style={ this.state.searchResult.length < 2 ? { visibility: 'hidden'} : {display: 'block'} }>
                      <button type="button"
                        onClick={this.onNext}
                        className="btn waves-effect waves-light"
                        id="nextModel"
                        disabled={this.state.disabledNext}
                        style={{ width: "100%" }}
                      >{t('inventory.viewCamera.nextModel')}
                      </button>
                    </div>
                  </div>

                </Row>
                <Row>
                  <div className="input-field col s3">
                    <select type='select'
                      label="Camera models"
                      onChange={this.onChange}
                      value={this.state.camera.cameraModel}
                      error={errors.cameraModel}
                      id="camera.cameraModel"
                      //disabled={!this.state.editable}
                      className={classnames("", {
                        invalid: errors.cameraModel
                      })} >
                      {cameraModels.map((item, index) => {
                        if (index === 0) {
                          return <option key={index} value={item.name} disabled>{item.name}</option>
                        } else {
                          return <option key={index} value={item.name} >{item.name}</option>
                        }
                      })}
                    </select>
                    <span id="cameraModelMessage" className="red-text">
                      {errors.cameraModel}
                    </span>
                  </div>
                  <div className="input-field col s2" style={{ textAlign: "left" }}>
                    <span className="active valign-wrapper">{t('input.gtin_number')}:  {this.state.camBarcode}</span>
                  </div>
                  <div className="input-field col s2" style={{ width: "14.8%", textAlign: "left" }}>
                    <span >{t('input.masterPack_barcode')}: <br /> {this.state.MasterBarcode}</span>
                  </div>
                  <div className="input-field col s2" style={{ width: "18.54%", textAlign: "left", marginLeft: "3%", marginRight: "-3%" }}>
                    <span className="active valign-wrapper">{t('dashboard_main.activationCode')}: <br /> {this.state.activationCode}</span>
                  </div>
                  <div className="input-field col s2">
                    <div className={user.role === "user" ? "hide" : null}>
                      <button type="button"
                        onClick={this.onChange}
                        className="btn waves-effect waves-light"
                        id="editable"
                        value="Edit Mode"
                        disabled={!this.props.match.params.id && !this.state.camera.id }
                        style={{ width: "100%" }}
                      >{t('inventory.viewCamera.editMode')}
                        </button>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="input-field col s3">
                    <input
                      placeholder=""
                      onChange={this.onChange}
                      value={this.state.camera.serialNumber}
                      error={errors.serialNumber}
                      id="camera.serialNumber"
                      type="text"
                      //disabled={!this.state.editable}
                      className={classnames("", {
                        invalid: errors.serialNumber
                      })}
                    />
                    <label className="active" htmlFor="serialNumber">{t('dashboard_main.serialNumber')}</label>
                    <span id="serialNumberMessage" className="red-text">
                      {errors.serialNumber}
                    </span>
                  </div>
                  <div className="input-field col s3">
                  {this.state.camera.sims.length > 1 || this.state.editable ?
                    <div>
                    <input
                      name= "sim"
                      placeholder=""
                      onChange={this.onChange}
                      value={this.state.camera.sims[0] || ""}
                      error={errors.sim}
                      id="camera.sims[0]"
                      type="text"
                      //disabled={!this.state.editable}
                      className={classnames("", {
                        invalid: errors.sim
                      })} />
                      <input
                      name= "sim"
                      placeholder=""
                      onChange={this.onChange}
                      value={this.state.camera.sims[1] || ""}
                      error={errors.sim}
                      id="camera.sims[1]"
                      type={this.state.camera.sims.length < 1 ? "hidden" : "text"}
                      //disabled={!this.state.editable}
                      className={classnames("", {
                        invalid: errors.sim
                      })} />
                      </div>
                      :
                      <div>
                      <input
                      name= "sim"
                      placeholder=""
                      onChange={this.onChange}
                      value={this.state.camera.sims[0] || ""}
                      error={errors.sim}
                      id="camera.sims[0]"
                      type="text"
                      //disabled={!this.state.editable}
                      className={classnames("", {
                        invalid: errors.sim
                      })} /> </div> }
                    <label className="active" htmlFor="sim">{t('dashboard_main.simCardNumber')}</label>
                    <span id="simMessage" className="red-text">
                      {errors.sim}
                    </span>
                  </div>
                  <div className="input-field col s2">
                    <input
                      name= "imei"
                      placeholder=""
                      onChange={this.onChange}
                      value={this.state.camera.imei || ""}
                      error={errors.imei}
                      id="camera.imei"
                      type="text"
                      //disabled={!this.state.editable}
                      className={classnames("", {
                        invalid: errors.imei
                      })}
                    />
                    <label className="active" htmlFor="imei">{t('dashboard_main.imei')}</label>
                    <span id="imeiMessage" className="red-text">
                      {errors.imei}
                    </span>
                  </div>
                  <div className="input-field col s2 offset-s1" >
                    <div className={user.role === "user" ? "hide" : null}>
                      <button type="button"
                        onClick={this.onChange}
                        className="btn waves-effect waves-light"
                        id="deleteCam"
                        disabled={!this.props.match.params.id && !this.state.camera.id }
                        value={this.state.camera.id}
                        style={{ width: "100%" }}
                      >{t('inventory.viewCamera.deleteCam')}
                      </button>
                      <span id="deletedMessage" className="red-text">
                        {errors.deleted}
                      </span>
                    </div>
                  </div>
                  <div className="input-field col s3 offset-s1" style={{ marginBottom: "0rem" }} >
                    <select type='select'
                      label="Reprint option"
                      onChange={this.onChange}
                      value={sticker}
                      error={errors.reprint}
                      disabled={!this.props.match.params.id && !this.state.camera.id }
                      style={{ width: "100%" }}
                      id="sticker" >
                      { stages.map((item, index) => {
                        if (index === 0) {
                          return <option key={index} value={item.id} disabled>{item.name}</option>
                        } else {
                          return <option key={index} value={item.id} >{item.name}</option>
                        }
                      }) }
                    </select>
                    {sticker === "sn_batch" &&
                      <Col s={12} m={4}>
                        <input
                          onChange={this.onChange}
                          onFocus={handleFocus}
                          defaultValue={this.state.quantity}
                          error={errors.input}
                          id="quantity"
                          type="text"
                        />
                        <label htmlFor="qty">Quantity</label>
                      </Col>
                    }
                  </div>
                  <div className="input-field col s2 offset-s9">
                      <button type="button"
                        onClick={this.onPrintCam}
                        className="btn waves-effect waves-light"
                        id="printCam"
                        style={{ width: "100%" }}
                        disabled={ !this.state.camera.id }
                      >{t('inventory.viewCamera.printMode')}
                        </button>
                        <span id="printMessage" className="red-text">
                        {this.state.reprintError}{this.state.errorPrint}{errors.input}
                      </span>
                  </div>
                </Row>
                <Row className={this.state.editable ? null : "hide"}>
                  <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                    <button
                      style={{
                        width: "300px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem"
                      }}
                      type="submit"
                      id="saveCamera"
                      className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                    >{t('dashboard_main.save_camera')}
                    </button>
                  </div>
                </Row>
              </form>
            </div>
          </div>
          <div>
            <Row>
              <Col m={12} s={12}>
                <div>
                  <br></br>
                  <Row>
                    <ReactTableBuilder columns={columns} data={cam} maxRows={20}/>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className = {this.state.snackBarError.length > 0 ? ['snackbarError', 'show'].join(" ") : 'snackbarError'}>
                { this.state.snackBarError.length > 0 ? this.state.snackBarError[0].text : '' }
            </div>
            <div className = {this.state.snackBar.length > 0 ? ['snackbar', 'show'].join(" ") : 'snackbar'}>
                { this.state.snackBar.length > 0 ? this.state.snackBar[0].text : '' }
            </div>
      </div>
      }
      </>
    );
  }
}
ViewCamera.propTypes = {
  searchQuery: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  search: state.search,
  searchResult : state.searchResult,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    { searchQuery }
  )
)(ViewCamera);
